import { Box } from '@mui/material';
import React from 'react';

const ServicePageLayout = ({ children }) => {
  return (
    <Box
      sx={{
        width: 450,
        mx: 'auto',
        height: 'calc(100vh - 130px)',
        '& img': { height: 200 },
      }}
    >
      {children}
    </Box>
  );
};

export default ServicePageLayout;
