const formatPhone = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  // let match = cleaned.match(/^[7]+(\d{3})(\d{3})(\d{2})(\d{2})$/);
  let match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/);

  // return match ? ['+7', ' (', match[1], ') ', match[2], '-', match[3], '-', match[4]].join('') : '–';
  return match
    ? [
        '+',
        match[1],
        ' (',
        match[2],
        ') ',
        match[3],
        '-',
        match[4],
        '-',
        match[5],
      ].join('')
    : '–';
};

const formatCurrency = (currency, style = 'currency') => {
  if (!currency) {
    currency = 0;
  }

  return new Intl.NumberFormat('ru-RU', {
    style: style,
    currency: 'RUB',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(currency);
};

const formatNumber = (number, titles) => {
  //titles example ['день', 'дня', 'дней']
  const cases = [2, 0, 1, 1, 1, 2];

  if (!number) {
    number = 0;
  }

  return (
    number +
    ' ' +
    titles[
      Math.abs(number) % 100 > 4 && Math.abs(number) % 100 < 20
        ? 2
        : cases[Math.abs(number) % 10 < 5 ? Math.abs(number) % 10 : 5]
    ]
  );
};

export { formatPhone, formatCurrency, formatNumber };
