import tableStyles from 'theme/components/tableStyles';
import { Close } from '@mui/icons-material';

import MuiSwitch from './MuiSwitch';
import MuiInputBase from './MuiInputBase';
import MuiRadio from './MuiRadio';
import MuiCheckbox from './MuiCheckbox';

/* overrides material components */

export default {
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        width: 40,
        height: 40,
      }),
    },
  },
  MuiDivider: {
    variants: [
      {
        props: { variant: 'dashed' },
        style: {
          borderStyle: 'dashed',
        },
      },
    ],
  },
  MuiStack: {
    defaultProps: {
      direction: 'row',
      alignItems: 'center',
    },
  },

  MuiTypography: {
    defaultProps: {
      // noWrap: true,
      variantMapping: {
        h7: 'h6',
        label: 'p',
        text: 'p',
        greyBody1: 'p',
        greyBody2: 'p',
      },
    },
  },

  MuiPaper: {
    defaultProps: {
      elevation: 2,
    },
  },

  MuiPopover: {
    defaultProps: {
      elevation: 2,
    },
  },

  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        ...theme.typography.h6,
        color: theme.palette.text.primary,
        display: 'block',
        marginBottom: theme.spacing(2),
      }),
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.grey[900],
        fontSize: '0.875rem',
      }),
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      root: () => ({
        marginLeft: 0,
        // marginRight: 0,
        '& > .MuiSwitch-root': {
          marginRight: 8,
        },
      }),
      label: ({ theme }) => ({
        color: theme.palette.text.primary,
        '&.Mui-disabled': {
          color: theme.palette.grey[400],
        },
      }),
    },
  },

  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        minHeight: 38,
        '&:hover': {
          boxShadow: 'none',
        },
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        marginBottom: theme.spacing(0.5),
        color: theme.palette.grey[700],
        fontSize: '0.75rem',
        '&.Mui-disabled': {
          color: theme.palette.grey[400],
        },
      }),
    },
  },
  MuiDialog: {
    defaultProps: {
      PaperProps: { elevation: 3 },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 2,
        backgroundColor: theme.palette.grey[300],
        height: 24,
      }),
      label: ({ theme }) => ({
        padding: '0 12px 0 8px',
        color: theme.palette.text.primary,
      }),
      deleteIcon: ({ theme }) => ({
        fontSize: 15,
        color: theme.palette.text.primary,
      }),
    },
    defaultProps: {
      deleteIcon: <Close />,
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      noOptions: ({ theme }) => ({
        color: theme.palette.grey[600],
      }),
    },
  },
  MuiCard: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(3),
      }),
    },
  },
  MuiMenu: {
    defaultProps: {
      disableAutoFocusItem: true,
    },
  },
  MuiRadio,
  MuiSwitch,
  MuiInputBase,
  MuiCheckbox,
  ...tableStyles,
};
