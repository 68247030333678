import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import theme from 'theme';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AuthProvider } from 'context/authContext';
import { StoreProvider } from 'context/storeContext';
import { ToastProvider } from 'context/toastContext';
import { client } from 'config/apolloConfig';
import { ApolloProvider } from '@apollo/client';
import { YMInitializer } from 'react-yandex-metrika';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ConfirmModalProvider } from 'context/confirmModalContext';
import CreationOfficeDataListContextProvider from 'pages/admin/partner/create/CreationOfficeDataListContextProvider/CreationOfficeDataListContextProvider';

import routes from './routes';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  beforeSend: (event) => {
    if (
      window.location.hostname === 'localhost' ||
      process.env.REACT_APP_STAND !== 'prod'
    ) {
      return null;
    }

    return event;
  },
});

export default function App() {
  const content = useRoutes(routes);
  const location = useLocation();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: location.pathname,
  });

  useEffect(() => {
    if (location.pathname !== route.to) {
      localStorage.clear();
    }

    setRoute((prev) => ({ to: location.pathname, from: prev.to }));
  }, [location.pathname]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <AuthProvider>
            <StoreProvider>
              <CreationOfficeDataListContextProvider>
                <ConfirmModalProvider>
                  <ToastProvider>
                    {process.env.REACT_APP_STAND === 'prod' && (
                      <YMInitializer
                        accounts={[84205264]}
                        options={{
                          clickmap: true,
                          trackLinks: true,
                          accurateTrackBounce: true,
                          webvisor: true,
                        }}
                      />
                    )}
                    <CssBaseline />
                    {content}
                  </ToastProvider>
                </ConfirmModalProvider>
              </CreationOfficeDataListContextProvider>
            </StoreProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}
