import { gql } from '@apollo/client';

export const MARK_ALL_AS_READ = gql`
  mutation markAllAsRead(
    $cursor: String
    $targetType: TargetType
    $where: NotificationFilterInput
    $order: [NotificationSortInput!]
  ) {
    markAllAsRead(
      first: 100
      after: $cursor
      targetType: $targetType
      where: $where
      order: $order
    ) {
      edges {
        node {
          id
          isRead
        }
      }
    }
  }
`;
