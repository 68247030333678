import { RIGHTS_ENUM } from 'constants/rights';

import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { AccessControl } from 'components/screens';
import { AuthContext } from 'context/authContext';

import NavList from './NavList';

const AdminLayout = () => {
  const { hasPermission } = useContext(AuthContext);

  return (
    <AccessControl
      permission={hasPermission(
        RIGHTS_ENUM.READ_PARTNER_LIST || RIGHTS_ENUM.READ_USER_LIST,
      )}
    >
      <Typography variant={'h3'} pb={3}>
        Администрирование
      </Typography>
      <Grid container>
        <Grid item xs={12} md={3} lg={2}>
          <NavList />
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <Outlet />
        </Grid>
      </Grid>
    </AccessControl>
  );
};

export default AdminLayout;
