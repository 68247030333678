import { gql } from '@apollo/client';

export const GET_REQUEST_STATUSES_OPTIONS = gql`
  query serviceRequestStatus {
    requestStatus: serviceRequestStatus {
      key
      value {
        key
        value
      }
    }
  }
`;
