import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const CircularLoading = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default CircularLoading;
