export default {
  styleOverrides: {
    root: {
      padding: 8,
      margin: -8,
    },
    switchBase: {
      '&.Mui-checked': {
        '& + .MuiSwitch-track': {
          opacity: 1,
        },
      },
    },
    '&.Mui-disabled': {
      '& .MuiSwitch-switchBase': {
        cursor: 'auto',
        '&.Mui-checked:hover': { background: 'none' },
        '&:hover': { background: 'none' },
      },
    },
    track: {
      borderRadius: 22 / 2,
      backgroundColor: '#557192',
      opacity: '1 !important',
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage:
          // eslint-disable-next-line
          "url(\"data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0.5 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.3675 4.161C1.27723 4.06499 1.1527 4.00851 1.02101 4.00382C0.889307 3.99914 0.761084 4.04664 0.66422 4.13599C0.567356 4.22534 0.509683 4.34932 0.503744 4.48097C0.497804 4.61262 0.544077 4.74129 0.632501 4.839L2.4385 6.7985C2.707 7.0615 3.107 7.0615 3.3555 6.8135L3.5375 6.634C4.19477 5.98761 4.85127 5.34044 5.507 4.6925L5.527 4.6725C6.13985 4.06853 6.74952 3.46136 7.356 2.851C7.44664 2.7561 7.49634 2.62937 7.49437 2.49815C7.4924 2.36693 7.43892 2.24175 7.34547 2.14961C7.25202 2.05747 7.1261 2.00577 6.99486 2.00565C6.86363 2.00554 6.73761 2.05703 6.644 2.149C6.04033 2.75617 5.43366 3.36034 4.824 3.9615L4.804 3.9815C4.17564 4.60265 3.54647 5.22298 2.9165 5.8425L1.3675 4.161Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A\")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        left: 12,
      },
      '&:after': {
        backgroundImage:
          // eslint-disable-next-line
            "url(\"data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 3.52867L2.23567 1.76433C2.1728 1.70361 2.0886 1.67002 2.0012 1.67078C1.9138 1.67154 1.83019 1.70659 1.76839 1.76839C1.70659 1.8302 1.67153 1.9138 1.67077 2.0012C1.67001 2.0886 1.70361 2.1728 1.76433 2.23567L3.52867 4L1.76433 5.76433C1.70361 5.8272 1.67001 5.9114 1.67077 5.9988C1.67153 6.0862 1.70659 6.1698 1.76839 6.23161C1.83019 6.29341 1.9138 6.32847 2.0012 6.32923C2.0886 6.32998 2.1728 6.29639 2.23567 6.23567L4 4.47133L5.76433 6.23567C5.8272 6.29639 5.9114 6.32998 5.9988 6.32923C6.0862 6.32847 6.1698 6.29341 6.2316 6.23161C6.29341 6.1698 6.32846 6.0862 6.32922 5.9988C6.32998 5.9114 6.29638 5.8272 6.23567 5.76433L4.47133 4L6.23567 2.23567C6.2675 2.20492 6.2929 2.16814 6.31037 2.12747C6.32784 2.0868 6.33703 2.04306 6.33742 1.9988C6.3378 1.95454 6.32937 1.91065 6.31261 1.86968C6.29585 1.82872 6.27109 1.7915 6.2398 1.7602C6.2085 1.7289 6.17128 1.70415 6.13032 1.68739C6.08935 1.67063 6.04546 1.6622 6.0012 1.66258C5.95694 1.66297 5.9132 1.67216 5.87253 1.68963C5.83186 1.7071 5.79508 1.7325 5.76433 1.76433L4 3.52867Z' fill='white' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A\");",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        right: 12,
      },
    },
    thumb: {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
      backgroundColor: '#fff',
    },
  },
};
