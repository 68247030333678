import { Box, Button, Stack, Typography } from '@mui/material';
import awaitingActualization from 'assets/images/awaiting-actualization.svg';
import { AuthContext } from 'context/authContext';
import { initialRedirect } from 'helpers';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServicePageLayout from 'layouts/ServicePageLayout';

const AwaitingActualization = () => {
  const { me, hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (me?.isContactDataActual) {
      navigate(initialRedirect(hasPermission));
    }
  }, [me]);

  const redirect = () => {
    window.open(process.env.REACT_APP_LK_URL);
  };

  return (
    <ServicePageLayout>
      <Stack
        direction={'column'}
        justifyContent="center"
        spacing={3}
        height="100%"
        textAlign={'center'}
      >
        <Box component="img" src={awaitingActualization} />
        <Typography variant="h4">Доступ заблокирован</Typography>
        <Typography>
          Актуализируйте данные в личном кабинете. Сервис будет доступен после
          того, как мы проверим обновления.
        </Typography>
        <Button variant="contained" onClick={redirect}>
          Перейти в личный кабинет
        </Button>
      </Stack>
    </ServicePageLayout>
  );
};

export default AwaitingActualization;
