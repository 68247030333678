import { RIGHTS_ENUM } from 'constants/rights';

import React, { useContext, useState } from 'react';
import {
  Avatar,
  Menu,
  MenuItem,
  Link as MuiLink,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { UserIcon } from 'assets/icons';
import { AuthContext } from 'context/authContext';

const AvatarMenu = () => {
  const { me, hasPermission } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenMenu = (e) => setAnchorEl(e.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const userPermission = hasPermission(RIGHTS_ENUM.READ_USER);

  return (
    <>
      <Stack sx={{ cursor: 'pointer' }} onClick={handleOpenMenu}>
        <Avatar src="/broken-image.jpg">{me?.shortDisplayName}</Avatar>
        <KeyboardArrowDownIcon />
      </Stack>
      <Menu
        anchorEl={anchorEl}
        sx={{ mt: 1.5 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {userPermission && (
          <MenuItem
            component={Link}
            to={`${process.env.REACT_APP_LK_URL}/admin/users/` + me?.id}
          >
            <Stack spacing={1.5}>
              <UserIcon />
              <Typography lineHeight={'18px'}>Личный кабинет</Typography>
            </Stack>
          </MenuItem>
        )}
        <MenuItem
          component={MuiLink}
          href={`${process.env.REACT_APP_API_URL}/api/logout`}
        >
          <Stack spacing={1.5}>
            <CloseIcon sx={{ width: 16, height: 16 }} />
            <Typography lineHeight={'18px'}>Выход</Typography>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMenu;
