import ym from 'react-yandex-metrika';

const GOALS_ENUM = {
  DOWNLOAD_REPORT: 'UPLOAD_REPORT',
  NOTIFICATIONS: 'NOTIFICATIONS',
  TO_REQUESTS: 'TO_REQUESTS',
  PARTNER_INFO: 'PARTNER_INFO',
  MANAGER_TO_WORK: 'MANAGER_TO_WORK',
  MANAGER_REJECT: 'MANAGER_REJECT',
  MANAGER_INVOICE_ISSUE: 'MANAGER_INVOICE_ISSUE',
  MANAGER_INVOICE_PAID: 'MANAGER_INVOICE_PAID',
  ADD_DOCUMENT: 'ADD_DOCUMENT',
  DOWNLOAD_DOCUMENT: 'DOWNLOAD_DOCUMENT',
  SEND_FORM: 'SEND_FORM',
  AUTOCHECKS_RESULT: 'AUTOCHECKS_RESULT',
  START_IDENTIFICATION: 'START_IDENTIFICATION',
  CI_APPROVE: 'CI_APPROVE',
  CI_REJECT: 'CI_REJECT',
  OPEN_HISTORY: 'OPEN_HISTORY',
  TSS_REJECT: 'TSS_REJECT',
  COMPLETELY_REJECT: 'COMPLETELY_REJECT',
  TSS_APPROVE: 'TSS_APPROVE',
  DEACTIVATE_USER: 'DEACTIVATE_USER',
  EDIT_USER: 'EDIT_USER',
  CREATE_PARTNER: 'CREATE_PARTNER',
  SAVE_USER: 'SAVE_USER',
};

const GOAL_ID = {
  [GOALS_ENUM.DOWNLOAD_REPORT]: 'download',
  [GOALS_ENUM.NOTIFICATIONS]: 'notifications',
  [GOALS_ENUM.TO_REQUESTS]: 'to_requests',
  [GOALS_ENUM.PARTNER_INFO]: 'partner_info',
  [GOALS_ENUM.MANAGER_TO_WORK]: 'manager_to_work',
  [GOALS_ENUM.MANAGER_REJECT]: 'manager_reject',
  [GOALS_ENUM.MANAGER_INVOICE_ISSUE]: 'manager_invoice_issue',
  [GOALS_ENUM.MANAGER_INVOICE_PAID]: ' manager_invoice_paid',
  [GOALS_ENUM.ADD_DOCUMENT]: 'add_doc',
  [GOALS_ENUM.DOWNLOAD_DOCUMENT]: '234399707',
  [GOALS_ENUM.SEND_FORM]: '234399919',
  [GOALS_ENUM.AUTOCHECKS_RESULT]: 'checks',
  [GOALS_ENUM.START_IDENTIFICATION]: 'start_identification',
  [GOALS_ENUM.CI_APPROVE]: 'ci_approve',
  [GOALS_ENUM.CI_REJECT]: 'ci_reject',
  [GOALS_ENUM.OPEN_HISTORY]: 'open_history',
  [GOALS_ENUM.TSS_REJECT]: 'tss_reject',
  [GOALS_ENUM.COMPLETELY_REJECT]: 'tss_reject',
  [GOALS_ENUM.TSS_APPROVE]: 'tss_approve',
  [GOALS_ENUM.DEACTIVATE_USER]: 'disable_user',
  [GOALS_ENUM.EDIT_USER]: 'edit_user',
  [GOALS_ENUM.CREATE_PARTNER]: 'create_partner',
  [GOALS_ENUM.SAVE_USER]: 'save_user',
};

const reachGoal = (goal) => {
  if (process.env.NODE_ENV === 'staging') {
    ym('reachGoal', GOAL_ID[goal]);
  }
};

export { GOALS_ENUM, GOAL_ID, reachGoal };
