const tableStyles = {
  MuiTableRow: {
    defaultProps: {
      component: 'div',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        '&.MuiTableRow-hover:hover': {
          backgroundColor: theme.palette.bg.elements,
          boxShadow: theme.shadows[2],
          a: {
            color: theme.palette.primary.main,
          },
        },
      }),
    },
  },

  MuiTableCell: {
    defaultProps: {
      component: 'div',
    },
    styleOverrides: {
      root: { fontSize: 14, border: 0 },
    },
  },

  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiTableCell-root': {
          color: theme.palette.grey[500],
          borderBottom: '1px solid #DDE2E8',
        },
      }),
    },
    defaultProps: {
      component: 'div',
    },
  },
  MuiTable: {
    defaultProps: {
      component: 'div',
    },
  },
  MuiTableBody: {
    defaultProps: {
      component: 'div',
    },
  },
};

export default tableStyles;
