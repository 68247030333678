import { Grid, IconButton, Paper, Snackbar, Typography } from '@mui/material';
import React, { createContext, useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ErrorIcon, SuccessStepIcon } from 'assets/icons';

export const ToastContext = createContext();

export const TOAST_TYPE_ENUM = {
  SUCCESS: <SuccessStepIcon width={24} height={24} />,
  ERROR: <ErrorIcon width={24} height={24} />,
};

export const ToastProvider = ({ children }) => {
  const [popUp, setPopUp] = useState(false);
  const [toast, setToast] = useState({});

  const addToast = useCallback(
    ({ title, subtitle, text, type }) => {
      setPopUp(true);
      setToast({ title, subtitle, text, type });
    },
    [setPopUp],
  );

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <Snackbar
        open={popUp}
        autoHideDuration={5000}
        onClose={() => setPopUp(false)}
        sx={{ width: 400 }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Grid container component={Paper} p={2.5} pb={1.5}>
          <Grid item xs={1.5}>
            {toast?.type}
          </Grid>
          <Grid item xs={8.5}>
            <Typography variant="h5">{toast?.title}</Typography>
            {toast?.subtitle && (
              <Typography variant="h6" mt={1}>
                {toast?.subtitle}
              </Typography>
            )}
            <Typography my={1}>{toast?.text}</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent={'flex-end'}>
            <IconButton
              size="large"
              onClick={() => setPopUp(false)}
              sx={{ color: 'grey.800', width: 24, height: 24 }}
            >
              <CloseIcon sx={{ width: 18, height: 18 }} />
            </IconButton>
          </Grid>
        </Grid>
      </Snackbar>
    </ToastContext.Provider>
  );
};
