import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';

const CustomModal = ({
  handleClose,
  open,
  title,
  subtitle,
  children,
  buttonText,
  handleSubmit,
  divider,
  loading,
  maxWidth,
  actions,
  noActions,
  fullWidth = true,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      sx={{ p: 3 }}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle id="dialog-title" component={'div'}>
        <Typography variant={'h4'} mb={subtitle ? 1 : 0}>
          {title}
        </Typography>
        {subtitle && <Typography variant={'greyBody1'}>{subtitle}</Typography>}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.400' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {divider && <Divider />}
      <DialogContent sx={{ pb: noActions ? 3 : 0 }}>{children}</DialogContent>
      {!noActions && (
        <DialogActions sx={{ p: 3 }}>
          {actions ? (
            actions
          ) : (
            <>
              <Button onClick={handleClose} color={'text'}>
                Отмена
              </Button>
              <LoadingButton
                autoFocus
                onClick={handleSubmit}
                variant={'contained'}
                loading={loading}
              >
                {buttonText}
              </LoadingButton>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  loading: PropTypes.bool,
  divider: PropTypes.bool,
  maxWidth: PropTypes.string,
  actions: PropTypes.node,
};

CustomModal.defaultProps = {
  maxWidth: 'xs',
};
