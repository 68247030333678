import React from 'react';
import PropTypes from 'prop-types';

import CreationOfficeDataListContext from '../CreationOfficeDataListContext/CreationOfficeDataListContext';

const CreationOfficeDataListContextProvider = ({ children }) => {
  const [registrationOffices, setRegistrationOffices] = React.useState([]);

  return (
    <CreationOfficeDataListContext.Provider
      value={{
        registrationOffices,
        setRegistrationOffices,
      }}
    >
      {children}
    </CreationOfficeDataListContext.Provider>
  );
};

CreationOfficeDataListContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CreationOfficeDataListContextProvider;
