import { Box, Button, Stack, Typography } from '@mui/material';
import nodata from 'assets/images/no-data.svg';
import React from 'react';
import ServicePageLayout from 'layouts/ServicePageLayout';

const NoData = () => {
  return (
    <ServicePageLayout>
      <Stack
        direction={'column'}
        justifyContent="center"
        spacing={3}
        height="100%"
        textAlign={'center'}
      >
        <Box component="img" src={nodata} />
        <Typography variant="h3">Авторизация выполнена</Typography>
        <Typography>
          Необходимо заполнить номер телефона в учетной записи. <br />
          Перейдите в личный кабинет для заполнения данных
        </Typography>
        <Button
          variant="contained"
          component={'a'}
          href={process.env.REACT_APP_LK_URL}
          target="_blank"
        >
          Перейти в личный кабинет
        </Button>
      </Stack>
    </ServicePageLayout>
  );
};

export default NoData;
