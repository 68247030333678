export const TARGET_TYPE_ENUM = {
  SERVICE_REQUEST: 'SERVICE_REQUEST',
  PARTNER: 'PARTNER',
  USER: 'USER',
};

export const NOTIFICATION_TYPE = {
  MANAGER_NEW: 'Новая Менеджер',
  MANAGER_WORK: 'В работе Менеджер',
  CI_WORK: 'В работе Сотрудник ЦИ',
  MANAGER_INVOICE_ISSUED: 'Выставлен счет Менеджер',
  CI_REJECTED: 'Отказ ЦИ',
  TSS_NEW: 'Новая СТП',
  TSS_WORK: 'В работе СТП',
  TSS_REJECTED: 'Отказ СТП',
  WR_ISSUE_VERIFICATION_FAILED: 'Ошибка WR',
  WR_ISSUE_VERIFICATION_REJECTED: 'Отказ WR',
  TSS_RECEIPT_NEW: 'Новая СТП (Расписка)',
  RECEIPT_AWAITING: 'Проверка расписки',
  COMPLETED: 'Завершена',
  TRANSFER: 'Перенос',
  TRANSFERRED_TO_YOU: 'Перенесена Вам',
  TRANSFERRED_FROM_YOU: 'Перенесена от Вас',
  MASS_TRANSFER: 'Массовый перенос заявок',
  RESPONSIBLE: 'Вы Исполнитель',
  NO_LONGER_RESPONSIBLE: 'Вы больше не Исполнитель',
  MORNING_COUNT: 'Всего заявок (утро)',
  EVENING_COUNT: 'Всего заявок (вечер)',
  UNASSIGNED: 'Незакрепленная',
  VERIFICATION_REQUEST: 'Запрос ручной проверки',
  VERIFICATION_DISABLED: 'Проверки отключены',
  VERIFICATION_REJECTED: 'Отказ в ручной проверке',
  AUTHORITY_REQUEST: 'Отключен группой контроля',
  DISABLED: 'Отключен',
  USER_WAS_FIRED: 'Уволен',
  BLOCKED: 'Заблокирован',
  UNBLOCKED: 'Разблокирован',
};
