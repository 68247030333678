import { gql } from '@apollo/client';

export const GET_ALL_USER_NOTIFICATIONS = gql`
  query userNotifications(
    $cursor: String
    $order: [NotificationSortInput!]
    $where: NotificationFilterInput
  ) {
    userNotifications(after: $cursor, where: $where, order: $order) {
      edges {
        node {
          id
          isRead
          message
          targetType
          processType
          userId
          notificationType
          source
          createdAt
          updatedAt
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
