import React, { createContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_REQUEST_STATUSES_OPTIONS } from 'graphql/request/query/getRequestStatus';
import { getPageName } from 'helpers';

export const StoreContext = createContext({});

export const STATUS_PROGRESS = {
  MANAGER_NEW: {
    color: 'success',
    level: 1,
    grouping: 'Менеджер',
    description: 'Заявка прошла проверки и передана менеджеру',
  },
  CI_REJECTED: {
    color: 'error',
    level: 4,
    grouping: 'Менеджер',
    description:
      'Сотрудник ЦИ отклонил заявку, заявка возвращается менеджеру партнера.',
  },
  MANAGER_WORK: {
    color: 'success',
    level: 1,
    grouping: 'Менеджер',
    description: 'Менеджер сменил статуса на В работе и начал работу по заявке',
  },
  MANAGER_INVOICE_ISSUED: {
    color: 'success',
    level: 2,
    grouping: 'Менеджер',
    description: 'Менеджер выставил счет, ожидает оплаты',
  },

  CREATED: {
    color: 'success',
    level: 1,
    grouping: 'Абонент',
    description: 'Заявка поступила в АРМ',
  },
  SUBSCRIBER_CONTAINER_GENERATION: {
    color: 'success',
    level: 7,
    grouping: 'Абонент',
    description: 'Заявка возвращена в ЛК для генерации контейнера ЭП абонентом',
  },
  SUBSCRIBER_INSTALL: {
    color: 'success',
    level: 10,
    grouping: 'Абонент',
    description: 'Пользователь устанавливает сертификат',
  },
  COMPLETED: {
    color: 'success',
    level: 11,
    grouping: 'Абонент',
    description: 'ЭП выдана абоненту, работа над заявкой успешно завершена.',
  },
  CI_NEW: {
    color: 'success',
    level: 3,
    grouping: 'ЦИ',
    description:
      'Менеджер отметил, что заявка оплачена. Заявка ожидает дальнейшей обработки сотрудником ЦИ',
  },
  TSS_REJECTED: {
    color: 'error',
    level: 6,
    grouping: 'ЦИ',
    description: 'Заявка отклонена СТП и возвращена в ЦИ для исправления.',
  },
  CI_WORK: {
    color: 'success',
    level: 4,
    grouping: 'ЦИ',
    description: 'Сотрудник ЦИ взял в работу заявку',
  },

  TSS_NEW: {
    color: 'success',
    level: 5,
    grouping: 'СТП',
    description:
      'Сотрудник ЦИ провел идентификацию. Заявка ожидает дальнейшей обработки сотрудником СТП',
  },
  TSS_WORK: {
    color: 'success',
    level: 6,
    grouping: 'СТП',
    description: 'Сотрудник СТП взял в работу заявку',
  },
  TSS_RECEIPT_WAITING: {
    color: 'success',
    level: 9,
    grouping: 'СТП',
    description: 'Получен сертификат ожидания загрузки расписки от абонента',
  },
  TSS_RECEIPT_NEW: {
    color: 'success',
    level: 9,
    grouping: 'СТП',
    description: 'Ожидание проверки загруженной расписки',
  },
  TSS_RECEIPT_WORK: {
    color: 'success',
    level: 9,
    grouping: 'СТП',
    description: 'Сотрудник СТП выполняет проверку расписки',
  },
  TSS_RECEIPT_REJECTED: {
    color: 'error',
    level: 9,
    grouping: 'СТП',
    description:
      'Сотрудник СТП отклонил файл расписки. Ожидаются исправления от абонента.',
  },

  WR_ISSUE_VERIFICATION: {
    color: 'success',
    level: 8,
    grouping: 'Web-Регистратор',
    description: 'Данные заявки переданы на проверку в WR',
  },
  WR_ISSUE_VERIFICATION_FAILED: {
    color: 'error',
    level: 8,
    grouping: 'Web-Регистратор',
    description: 'Проверки не пройдены',
  },
  WR_ISSUE_GENERATION: {
    color: 'success',
    level: 8,
    grouping: 'Web-Регистратор',
    description: 'Генерация сертификата в УЦ',
  },
  WR_ISSUE_REGISTRATION: {
    color: 'success',
    level: 8,
    grouping: 'Web-Регистратор',
    description:
      'Производится регистрация сертификата в контролирующих органах',
  },

  WR_ISSUE_GENERATION_ERROR: {
    color: 'error',
    level: 8,
    grouping: 'Web-Регистратор',
    description: 'Ошибка при генерации сертификата в УЦ',
  },
  COMPLETELY_REJECTED: {
    color: 'cancel',
    level: 11,
    grouping: 'Отклонённые',
    description:
      'Заявка отклонена отклонена Вендором или сотрудником СТП, дальнейшая работа с заявкой не предусматривается',
  },
  AT_CREATION_REJECTED: {
    color: 'cancel',
    level: 11,
    grouping: 'Отклонённые',
    description: 'Заявка поступила в АРМ но не прошла входные проверки',
  },
  WR_ISSUE_VERIFICATION_REJECTED: {
    color: 'cancel',
    level: 11,
    grouping: 'Отклонённые',
    description: 'Заявка отклонена в WR',
  },
  MANAGER_REJECTED: {
    color: 'cancel',
    level: 11,
    grouping: 'Отклонённые',
    description:
      'Менеджер отклонил заявку, дальнейшая работа не предусматривается',
  },
};

export const StoreProvider = ({ children }) => {
  const { data } = useQuery(GET_REQUEST_STATUSES_OPTIONS);
  const requestStatusesData = data?.requestStatus || [];
  const pageName = getPageName();
  const REQUEST_STATUS = useMemo(
    () =>
      requestStatusesData.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.key]: {
            name: curr.value.find((i) => i.key === 'DEFAULT')?.value,
            MANAGER: curr.value.find((i) => i.key === 'MANAGER')?.value,
            CI: curr.value.find((i) => i.key === 'CI')?.value,
            TSS: curr.value.find((i) => i.key === 'TSS')?.value,
            OTHER: curr.value.find((i) => i.key === 'OTHER')?.value,
            color: STATUS_PROGRESS[curr.key].color,
            level: STATUS_PROGRESS[curr.key].level,
            description: STATUS_PROGRESS[curr.key].description,
            grouping: STATUS_PROGRESS[curr.key].grouping,
          },
        }),
        {},
      ),
    [data],
  );

  const REQUEST_STATUS_OPTIONS = useMemo(
    () =>
      Object.entries(REQUEST_STATUS).map(([key, value]) => ({
        id: key,
        displayName: value.name || value[pageName] || value.OTHER,
        grouping: value.grouping,
      })),
    [data, pageName],
  );

  return (
    <StoreContext.Provider value={{ REQUEST_STATUS, REQUEST_STATUS_OPTIONS }}>
      {children}
    </StoreContext.Provider>
  );
};
