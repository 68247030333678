import { IconButton, Stack, Tooltip } from '@mui/material';
import { CopyIcon } from 'assets/icons';
import React, { useState } from 'react';

const CopyWrapper = ({ children, copyValue }) => {
  const [message, setMessage] = useState('Скопировать');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyValue);
    setMessage('Скопировано в буфер обмена');
  };

  const mouseLeaveHandler = () => {
    setTimeout(() => {
      setMessage('Скопировать');
    }, 200);
  };

  return (
    <Stack spacing={1}>
      {children}
      {copyValue && (
        <Tooltip
          title={message}
          componentsProps={{
            tooltip: { sx: { mb: '0px !important' } },
          }}
          placement={'top'}
        >
          <IconButton
            onClick={copyToClipboard}
            size="small"
            onMouseLeave={mouseLeaveHandler}
          >
            <CopyIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default CopyWrapper;
