import Error404 from 'pages/Error404';
import React from 'react';

import RequestAccessDenied from './RequestAccessDenied';
import RequestPartialAccessDenied from './RequestPartialAccessDenied';
import PageAccessDenied from './PageAccessDenied';

const AccessControl = ({ error, children, permission }) => {
  const extensions = error?.graphQLErrors[0]?.extensions;
  const error404 =
    error?.networkError?.result?.errors[0]?.message ===
    'UUID cannot parse the given literal of type `StringValueNode`.';

  if (extensions?.code === 'PartialAccessDenied: AccessDenied') {
    return <RequestPartialAccessDenied extensions={extensions} />;
  } else if (extensions?.code === 'AccessDenied') {
    return <RequestAccessDenied />;
  } else if (error404) {
    return <Error404 />;
  } else if (permission) {
    return children;
  } else {
    return <PageAccessDenied />;
  }
};

export default AccessControl;
