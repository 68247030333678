import { Box, Typography } from '@mui/material';
import React from 'react';
import permissionDenied from 'assets/images/permission-denied.svg';

const PageAccessDenied = () => {
  return (
    <Box textAlign={'center'} mx={'auto'} maxWidth={400} pt={10}>
      <img src={permissionDenied} alt={'lock'} height={162} />
      <Typography variant={'h4'} mb={2}>
        Нет доступа к странице
      </Typography>
      <Typography>
        Если вы считаете, что доступ был отключён по ошибке, обратитесь,
        пожалуйста, в службу поддержки
      </Typography>
      <Typography sx={{ textDecoration: 'underline' }}>
        <a href="mailto:astral@astral.ru">astral@astral.ru</a>
      </Typography>
    </Box>
  );
};

export default PageAccessDenied;
