import React from 'react';
import { List, ListItem, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

const NavList = () => {
  const location = useLocation();

  const routes = [
    { title: 'Пользователи', to: '/admin/users' },
    { title: 'Партнёры', to: '/admin/partners' },
    // {title: 'Отзывы', to: '/admin/reviews'}
  ];

  return (
    <List>
      {routes.map((item, index) => (
        <ListItem
          component={Link}
          to={item.to}
          disableGutters
          key={`list-item-${index}`}
        >
          <Typography
            variant="subtitle1"
            noWrap
            color={
              location.pathname.includes(item.to)
                ? 'primary.main'
                : 'text.primary'
            }
          >
            {item.title}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default NavList;
