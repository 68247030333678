import { Suspense, lazy } from 'react';
import { ProgressLoading } from 'components/loading';
import MainLayout from 'layouts/MainLayout';
import AdminLayout from 'layouts/AdminLayout';
import { Navigate } from 'react-router-dom';
import NoData from 'pages/NoData';
import AwaitingRegistration from 'pages/AwaitingRegistration';
import BlockScreen from 'pages/BlockScreen';
import AwaitingActualization from 'pages/AwaitingActualization';
import Error404 from 'pages/Error404';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<ProgressLoading />}>
      <Component {...props} />
    </Suspense>
  );

const Users = Loadable(lazy(() => import('pages/admin/user/list/Users')));
const User = Loadable(lazy(() => import('pages/admin/user/view/User')));

const Reviews = Loadable(lazy(() => import('pages/admin/review/list/Reviews')));

const Partners = Loadable(
  lazy(() => import('pages/admin/partner/list/Partners')),
);
const Partner = Loadable(
  lazy(() => import('pages/admin/partner/view/Partner')),
);
const CreatePartner = Loadable(
  lazy(() => import('pages/admin/partner/create/CreateForm')),
);

const ManagerRequests = Loadable(
  lazy(() => import('pages/request/manager/list/Requests')),
);
const ManagerRequest = Loadable(
  lazy(() => import('pages/request/manager/view/Request')),
);
const CiRequests = Loadable(
  lazy(() => import('pages/request/ci/list/Requests')),
);
const CiRequest = Loadable(lazy(() => import('pages/request/ci/view/Request')));
const TssRequests = Loadable(
  lazy(() => import('pages/request/tss/list/Requests')),
);
const TssRequest = Loadable(
  lazy(() => import('pages/request/tss/view/Request')),
);
const VendorRequests = Loadable(
  lazy(() => import('pages/request/vendor/list/Requests')),
);
const VendorRequest = Loadable(
  lazy(() => import('pages/request/vendor/view/Request')),
);

const PricePlan = Loadable(
  lazy(() => import('pages/request/manager/view/components/PricePlan')),
);
const Details = Loadable(
  lazy(() => import('pages/request/manager/view/components/Details')),
);
const DocumentWorkflow = Loadable(
  lazy(() => import('pages/request/manager/view/components/DocumentWorkflow')),
);

const routes = [
  {
    element: <div />,
    index: true,
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: 'ci-requests',
        children: [
          {
            index: true,
            element: <CiRequests />,
          },
          {
            path: ':requestId',
            element: <CiRequest />,
          },
        ],
      },
      {
        path: 'tss-requests',
        children: [
          {
            index: true,
            element: <TssRequests />,
          },
          {
            path: ':requestId',
            element: <TssRequest />,
          },
        ],
      },
      {
        path: 'vendor-requests',
        children: [
          {
            index: true,
            element: <VendorRequests />,
          },
          {
            path: ':requestId',
            element: <VendorRequest />,
          },
        ],
      },
      {
        path: 'manager-requests',
        children: [
          {
            index: true,
            element: <ManagerRequests />,
          },
          {
            path: ':requestId',
            element: <ManagerRequest />,
            children: [
              {
                index: true,
                element: <Navigate to="details" replace />,
              },
              {
                path: 'price-plan',
                element: <PricePlan />,
              },
              {
                path: 'details',
                element: <Details />,
              },
              {
                path: 'documents/:documentId',
                element: <DocumentWorkflow />,
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        element: <AdminLayout />,
        children: [
          {
            element: <Navigate to="/admin/users" replace />,
            index: true,
          },
          {
            path: 'users',
            children: [
              {
                index: true,
                element: <Users />,
              },
              {
                path: ':userId',
                element: <User />,
              },
            ],
          },
          {
            path: 'reviews',
            children: [
              {
                index: true,
                element: <Reviews />,
              },
              {
                path: ':reviewId',
                element: <div />,
              },
            ],
          },
          {
            path: 'partners',
            children: [
              {
                index: true,
                element: <Partners />,
              },
              {
                path: ':partnerId',
                element: <Partner />,
              },
              {
                path: 'create',
                element: <CreatePartner />,
              },
            ],
          },
        ],
      },
      { path: 'no-data', element: <NoData /> },
      { path: 'block', element: <BlockScreen /> },
      { path: 'awaiting-registration', element: <AwaitingRegistration /> },
      { path: 'awaiting-actualization', element: <AwaitingActualization /> },
      { path: '*', element: <Error404 /> },
    ],
  },
];

export default routes;
