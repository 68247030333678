import { PAGE_ENUM } from 'constants/page';
import { RIGHTS_ENUM } from 'constants/rights';

export * from './name';

export * from './formatters';

export * from './metrika';

export const cleanData = (data) =>
  Object.entries(data).reduce(
    (a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)),
    {},
  );

export const getPageName = () => {
  if (location.pathname.includes('/manager-requests')) {
    return PAGE_ENUM.MANAGER;
  } else if (location.pathname.includes('/ci-requests')) {
    return PAGE_ENUM.CI;
  } else if (location.pathname.includes('/tss-requests')) {
    return PAGE_ENUM.TSS;
  } else if (location.pathname.includes('/vendor-requests')) {
    return PAGE_ENUM.VENDOR;
  } else {
    return PAGE_ENUM.OTHER;
  }
};

export const camelToSnake = (str) =>
  str[0].toLowerCase() +
  str
    .slice(1, str.length)
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const checkNoPreview = (fileExtension) =>
  fileExtension === 'doc' ||
  fileExtension === 'docx' ||
  fileExtension === 'zip' ||
  fileExtension === 'rar';

export const initialRedirect = (hasPermission) => {
  const readManagerList = hasPermission(
    RIGHTS_ENUM.READ_SERVICE_REQUEST_MANAGER_LIST,
  );
  const readCIList = hasPermission(RIGHTS_ENUM.READ_SERVICE_REQUEST_CI_LIST);
  const readTSSList = hasPermission(RIGHTS_ENUM.READ_SERVICE_REQUEST_TSS_LIST);
  const readVendorList = hasPermission(
    RIGHTS_ENUM.READ_SERVICE_REQUEST_VENDOR_LIST,
  );

  if (readManagerList) {
    return '/manager-requests';
  } else if (readCIList) {
    return '/ci-requests';
  } else if (readTSSList) {
    return '/tss-requests';
  } else if (readVendorList) {
    return '/vendor-requests';
  } else {
    return '/';
  }
};
