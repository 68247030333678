import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { AuthContext } from 'context/authContext';
import { ProgressLoading } from 'components/loading';
import Error500 from 'pages/Error500';
import * as Sentry from '@sentry/react';

import NavBar from './NavBar';

const MainLayout = () => {
  const { loading } = useContext(AuthContext);

  return (
    <ProgressLoading loading={loading}>
      <Box sx={{ width: '100%', px: 12 }}>
        <NavBar />
        <Sentry.ErrorBoundary
          fallback={({ eventId }) => <Error500 error={eventId} />}
        >
          <Box sx={{ py: 4 }}>
            <Outlet />
          </Box>
        </Sentry.ErrorBoundary>
      </Box>
    </ProgressLoading>
  );
};

export default MainLayout;
