import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';

const graphqlRoot =
  process.env.REACT_APP_API_URL?.replace(/^https?\:\/\//, '') ??
  window.location.host;
const graphqlWsUri = `wss://${graphqlRoot}/api/graphql`;
const graphqlHttpUri = `${process.env.REACT_APP_API_URL ?? ''}/api/graphql`;
const loginUri = `${process.env.REACT_APP_API_URL ?? ''}/api/login?url=${
  window.location.href
}`;
const blockUri = `${window.location.origin}/block`;

const wsLink = new WebSocketLink(
  new SubscriptionClient(graphqlWsUri, {
    options: { reconnect: true },
  }),
);

const httpLink = createUploadLink({
  uri: graphqlHttpUri,
  fetchOptions: {
    credentials: 'include',
  },
  credentials: 'include',
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${extensions?.message}, Location: ${locations}, Path: ${path}`,
      );

      switch (extensions?.message) {
        case 'Blocked: User is deactivated':
          if (location.pathname !== '/block') {
            window.location.href = blockUri;
          }

          break;
      }

      switch (extensions?.code) {
        case 'AUTH_NOT_AUTHENTICATED':
          if (window.location.pathname !== '/no-data') {
            window.location.href = loginUri;
          }
      }
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

export const client = new ApolloClient({
  link: from([errorLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          users: relayStylePagination(),
          serviceRequestsManager: relayStylePagination(),
          serviceRequestsCi: relayStylePagination(),
          serviceRequestsTss: relayStylePagination(),
          serviceRequestsVendor: relayStylePagination(),
          partners: relayStylePagination(),
          userNotifications: relayStylePagination(),
          partnerNotifications: relayStylePagination(),
          serviceRequestNotifications: relayStylePagination(),
        },
      },
    },
  }),
});
