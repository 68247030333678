import * as React from 'react';
import 'assets/styles/styles.css';
import 'moment/locale/ru';
import 'nprogress/nprogress.css';
import { BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';
import { createRoot } from 'react-dom/client';

import App from './App';

moment.updateLocale('ru', {
  months: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
});

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw Error('React root element is not find');
}

createRoot(rootElement).render(
  <Router>
    <App />
  </Router>,
);
