import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';

import palette from './palette';
import typography from './typography';
import components from './components';
import shadows from './shadows';

const theme = createTheme(
  {
    palette,
    shadows,
    typography,
    components,
  },
  ruRU,
);

export default theme;
